import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo/Seo';
import CategoryBoxContainer from '@/containers/CategoriesProduct/CategoryBoxContainer';

import { SecondaryCategoryQuery } from '../../graphql-types';

interface Props {
  data: SecondaryCategoryQuery;
}

export const query = graphql`
  query TertiaryCategoryBox($id: String!) {
    prismicSecondaryCategory(id: { eq: $id }) {
      id
      data {
        name {
          text
        }
        tertiary_categories {
          tertiary_category {
            document {
              ... on PrismicTertiaryCategory {
                id
                uid
                data {
                  name {
                    text
                  }
                  image {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TertiaryCategoryBoxTemplate = ({ data }: Props): JSX.Element => {
  const CatName = data?.prismicSecondaryCategory?.data?.name?.text;
  console.log(CatName, 'CATNAMEEEEE')
  return (
    <Layout invertNav type="magasin">
      <Seo
        title={`Catégorie ${CatName}`}
        description={`Catégories tertiaires de ${CatName}`}
      />
      <CategoryBoxContainer data={data} />
    </Layout>
  );
};

export default TertiaryCategoryBoxTemplate;
